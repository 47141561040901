html,
body {
  padding: 0px;
  /* overflow: hidden; */
}
.result-page-off {
  display: none;
}
.result-page-on {
  z-index: 1;
  background: url("/src/img/cyrosleep1.jpg") no-repeat;
  background-position: left top;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.result-alph-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.result-btn-left,
.result-btn-right {
  margin: auto;
  text-align: center;
  padding: 1px;
  width: 100px;
  position: absolute;
  font-size: large;
  font-weight: bold;
}
.result-btn-left {
  background-color: #27ad05;
}

.result-btn-right {
  background-color: #e84b16;
}

.result-number {
  background-color: #27ad05;
  width: 100px;
  margin: 0;
  border-radius: 0;
}

.result-number-container-on {
  /* background-size: 200px; */
  position: absolute;
  display: flex;
  flex-direction: column;

  width: 100px;
  /* height: 4200px; */
}
.result-number-container-off {
  display: none;
}

.result-revote-on {
  background-size: 200%;
  animation: buttonLoad 10s linear;
  background-image: linear-gradient(to right, #617add 50%, #97e85d 50%);
  position: absolute;
  margin-left: 0px;
  left: 50%;
  width: 99%;
  transform: translate(-50%);
  font-size: larger;
}

@keyframes buttonLoad {
  from {
    background-position: 100%;
  }
  to {
    background-position: 0%;
  }
}

.result-revote-off {
  display: none;
}

.result-photos-layout {
  border-radius: 0px 20px 0px 0px;
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translate(-50%);
  display: flex;
  align-content: flex-start;
  justify-content: space-around;

  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 10px;
  overflow: auto;
  width: 97%;
}

.result-voter-container {
  border-radius: 30px;
  min-height: 70px;
  position: relative;
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 50px;
  justify-content: space-around;
  background-color: rgba(3, 14, 14, 0.8);
  border-style: solid;
  border-width: 5px;
  padding: 0px 10px 0px 10px;
}
/* User voter against photot and text*/

.result-photo-container {
  position: absolute;
  top: -50px;
  text-align: center;
  color: rgb(246, 255, 240);
  height: 50px;
  width: 50px;
  padding: 5px;
}

.result-photo {
  width: 100%;
  height: 100%;
  background-color: rgb(7, 54, 2);
  border-radius: 50%;
}
.result-photo-selected-true {
  position: absolute;
  width: 40px;
  height: 50px;
  transform: rotate(320deg);
  top: -10px;
  left: -90px;
}
.result-photo-selected-false {
  display: none;
}
.result-photo-text {
  color: white;
  position: absolute;
  font-size: 20px;
  border-radius: 35px;
  padding-left: 10px;
  padding-right: 15px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  background-color: rgba(4, 3, 3, 0.688);
  top: 0px;
  left: 100%;
  white-space: nowrap;
  /* transform: translate(-50%, -50%); */
}

/* Voter photot and text*/
.result-photo-container-voters {
  position: relative;
  text-align: center;
  color: azure;
  height: 50px;
  width: 50px;
  padding: 10px;
}
.result-photo-text-voters {
  color: white;
  position: absolute;
  font-size: 15px;
  border-radius: 35px;
  padding-left: 10px;
  padding-right: 15px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  background-color: rgba(4, 3, 3, 0.688);
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.result-userButtons:hover {
  all: initial;
}
.result-userButtons {
  all: initial;
}

.result-killed-text {
  position: absolute;
  top: 5px;
  color: white;
  text-shadow: 0 0 6px black, 0 0 20px black, 0 0 10px black, 0 0 15px black;
  text-align: center;
}

@media (orientation: portrait) {
  .result-photos-layout {
    height: calc(100vh - 100px);
    /* background-color: rgba(3, 14, 14, 0.5); */
  }
  .result-voter-container {
    border-radius: 30px;
    width: 95%;
  }
  .result-btn-left {
    border-radius: 0px 0px 0px 20px;
    bottom: 10px;
    left: 50%;
    transform: translate(-100%);
  }
  .result-btn-right {
    border-radius: 0px 20px 0px 0px;
    bottom: 10px;
    left: 50%;
  }
  .result-revote-on {
    bottom: 1px;
  }
  .result-number {
    background-color: #27ad05;
    width: 100px;
  }
  .result-number-container-on {
    bottom: 40px;
    left: 50%;
    transform: translate(-100%);
    max-height: 80%;
    border-radius: 20px 20px 0 0;
    overflow: auto;
  }
  .result-killed-text {
    width: 100%;
  }

  .result-photo-container {
    left: 25%;
  }
}
@media (orientation: landscape) {
  .result-photos-layout {
    height: calc(100vh - 45px);
    /* background-color: rgba(3, 14, 14, 0.5); */
  }
  .result-btn-left {
    border-radius: 20px 0px 0px 0px;
    top: 5px;
    right: 105px;
  }

  .result-btn-right {
    border-radius: 0px 0px 20px 0px;
    top: 5px;
    right: 5px;
  }
  .result-number-container-on {
    top: 32px;
    right: 105px;
    max-height: 80%;
    border-radius: 0 0 20px 20px;
    overflow: auto;
  }

  .result-revote-on {
    top: 1px;
  }

  .result-voter-container {
    min-width: 150px;
  }
  .result-killed-text {
    width: 70%;
    left: 0;
  }
  .result-photo-container {
    left: 10%;
  }
}
