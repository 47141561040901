.popup-window {
  color: rgb(186, 198, 208);
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.95);
  align-content: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
}

.popup-window-off {
  display: none;
}
.popup-heading {
  padding-left: 20px;
  padding-right: 20px;
}

.popUp-message {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  margin: 0;
}
.popup-yes {
  background: rgb(156, 249, 143);
}
.popup-no {
  background: rgb(237, 156, 105);
}
