html {
  background: url("/src/img/roomBackground.jpg") no-repeat;
  background-position: left top;
  background-attachment: fixed;
  /* animation: backGroundMove 40s infinite linear;
  animation-direction: alternate; */
  background-size: cover;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: azure;
  text-shadow: 2px 2px 4px rgb(10, 10, 10), 3px 3px 4px rgb(231, 195, 14);
}
input,
button {
  border-radius: 25px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  box-shadow: 2px -2px 10px black, -2px 2px 10px black;
}

/* input:focus {
  padding: 7px;
}

button:hover {
  padding: 5px 17px 5px;
} */

@keyframes backGroundMove {
  from {
    background-position: left top;
  }
  to {
    background-position: right top;
  }
}
