html,
body {
  padding: 0px;
  /* overflow: hidden; */
}
.vote-page-off {
  display: none;
}
.vote-page-on {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.95);
  align-content: center;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
/* h1 {
  font-weight: 100;
  font-family: "Brush Script MT";
}

span {
  font-size: min(5.5vw, 20px);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
} */
.vote-btn {
  font-size: x-large;
  font-weight: bold;
  position: relative;
  top: 50px;
  padding: 5px;
  min-width: 150px;
  margin: auto;
  text-align: center;
}
.vote-photo-container {
  position: relative;
  text-align: center;
  color: azure;
  height: 70px;
  width: 70px;
  padding: 5px;
}
.vote-photos-layout {
  min-width: 100%;
  position: relative;
  /* top: 50%; */
  /* background-color: rgba(0, 0, 0, 0.95); */
  display: flex;
  /* align-content: flex-end; */
  justify-content: space-around;
  /* flex-wrap: wrap; */
  /* flex-direction: row; */
  /* height: 100vh;
  width: 100vw; */
}

.vote-photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.vote-photo-selected-true {
  position: absolute;
  width: 50%;
  height: 50%;
  /* border-radius: %; */
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vote-photo-selected-false {
  display: none;
}
.vote-photo-text {
  color: white;
  position: absolute;
  font-size: 20px;
  text-align: center;
  border-radius: 35px;
  padding-left: 5px;
  padding-right: 5px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  background-color: rgba(4, 3, 3, 0.688);
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vote-userButtons:hover {
  all: initial;
}
.vote-userButtons {
  all: initial;
}

.vote-text-container {
  color: rgb(214, 45, 33);
  text-shadow: 0px 0px 3px rgb(56, 54, 54), 0px 0px 4px rgb(226, 221, 221);
  position: relative;
  font-size: min(22vw, 30px);
  text-align: center;
  font-weight: 100;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
