.join-sheet-off {
  display: none;
}
.join-sheet-on {
  font-size: min(7vw, 30px);
  font-weight: bold;
  text-shadow: 0px 0px 3px rgb(70, 67, 67), 0px 0px 4px rgb(226, 221, 221);
}
.join-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.join-text {
  position: absolute;
  font-size: 35px;
  border-radius: 35px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(4, 3, 3, 0.688);
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (orientation: portrait) {
  .join-sheet-on {
    text-align: center;
  }

  .join-photo-container {
    position: relative;
    text-align: center;
    color: azure;
  }

  .join-join {
    font-size: 30px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
}

@media (orientation: landscape) {
  .join-text-container {
    position: absolute;
    left: calc((100% - 50px) / 2);
    top: calc(50%);
    text-align: center;
    transform: translate(0, -50%);
  }

  .join-photo-container {
    position: absolute;
    top: calc(50% - 100px);
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    color: azure;
  }
  .join-join {
    font-size: 30px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    margin: 0;
  }
}
