.game-sheet-on {
  position: absolute;
  top: 0;
  left: 50%;
  text-shadow: 0px 0px 3px rgb(56, 54, 54), 0px 0px 4px rgb(226, 221, 221);
  width: 100%;
  height: 100%;
  min-width: 240px;
  max-width: 2000px;
  transform: translate(-50%);
}
.game-sheet-off {
  display: none;
}
.game-user-sheet-off {
  display: none;
}
.game-admin-sheet-off {
  display: none;
}
.game-text-container {
  position: relative;
  font-size: min(22vw, 44px);
  padding-left: 10px;
  margin: 2px;
  font-weight: 100;
  font-family: "Brush Script MT";
}

span {
  font-size: min(5.5vw, 20px);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.game-photos-layout,
.game-photos-layout-admin {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 10px;
  border: 4px solid rgb(0, 0, 0);
  overflow: auto;
  min-height: 60px;
}

.game-photo-container {
  position: relative;
  text-align: center;
  color: azure;
  min-height: 70px;
  min-width: 70px;
  max-height: 140px;
  max-width: 140px;
  height: 9vw;
  width: 9vw;
  padding: 12px 8px 12px 8px;
}

.game-photo,
.game-photo-poisoned {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.game-photo-poisoned {
  box-shadow: 0px 0px 20px rgb(242, 246, 2), 0px 0px 30px #03ff0f;
}

.game-photo-selected-true {
  position: absolute;
  width: 50%;
  height: 50%;
  /* border-radius: %; */
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.game-photo-selected-false {
  display: none;
}
.game-photo-text,
.game-photo-text-poisoned {
  color: white;
  position: absolute;
  font-size: 15px;
  text-align: center;
  /* word-wrap: break-word; */
  border-radius: 20px;
  padding: 2px 5px 2px 5px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  background-color: rgba(6, 5, 5, 0.9);
  bottom: 0px;
  left: 50%;
  max-width: 90%;
  overflow: hidden;
  transform: translate(-50%);
}
.game-photo-text-poisoned {
  color: #03ff0f;
}

.game-userButtons:hover {
  all: initial;
}
.game-userButtons {
  all: initial;
}

@media (orientation: portrait) {
  .game-sheet-on {
    text-align: center;
  }

  .game-photos-layout,
  .game-photos-layout-admin {
    max-height: calc(100vh - 82px);
    width: 95%;
  }

  .game-button-container {
    position: relative;
    top: 10px;
  }
}
@media (orientation: landscape) {
  .game-sheet-on {
    text-align: left;
  }

  .game-photos-layout,
  .game-photos-layout-admin {
    max-height: calc(100vh - 82px);
    width: 97%;
    /* max-height: 100%; */
  }

  .game-button-container {
    position: absolute;
    top: 17px;
    right: 10px;
  }
}
