.loading-window-on {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
}

.loading-window-off {
  display: none;
}

.loading-hatchet {
  background-image: url("../../img/rocket.png");
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: 100%;
  animation: moveHatach 4s infinite linear;
  /* animation-direction: alternate; */
  top: 40%;
  left: 40%;
}

.loading-earth {
  background-image: url("../../img/earth.png");
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: 100%;
  /* animation-direction: alternate; */
  top: 40%;
  left: 10%;
  /* transform: translateX(-50%); */
}
.loading-planet {
  background-image: url("../../img/planet.png");
  position: absolute;
  width: 60px;
  height: 60px;
  background-size: 100%;
  transform: translateX(-50%);
  /* animation-direction: alternate; */
  top: 40%;
  left: 80%;
}

@keyframes moveHatach {
  0% {
    transform: rotate(90deg);
    left: 10%;
  }
  50% {
    left: 70%;
    transform: rotate(90deg);
  }
  55% {
    left: 75%;
    transform: rotate(270deg);
  }
  95% {
    left: 15%;
    transform: rotate(270deg);
  }
  100% {
    left: 10%;
    transform: rotate(90deg);
  }
}
