.login-sheet-on {
  font-size: min(10vw, 50px);
  font-weight: bold;
  text-align: center;
}
.login-sheet-off {
  display: none;
}
.login-welcome,
.login-welcome-of {
  font-family: "Brush Script MT";
  font-weight: 200;
  /* text-shadow: 2px 2px 4px rgb(10, 10, 10), 3px 3px 4px rgb(231, 195, 14); */
  position: relative;
  /* word-spacing: 100vw; */
  margin: 0;
}
.login-welcome-of {
  font-size: 22px;
}

.login-login {
  font-size: 25px;
  /* text-shadow: 0px 0px 3px rgb(9, 9, 9), 0px 0px 4px rgb(226, 221, 221); */
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

/* testing only delete */
.testing {
  position: absolute;
  background-color: rgb(25, 142, 245);
  max-width: 350px;
}

@media (orientation: landscape) {
  .login-container {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translate(0, -50%);
  }
  .login-form-container {
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
  }
}
