.waiting-page-on {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.waiting-page-off {
  display: none;
}

.waiting-text-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.waiting-text {
  background-color: rgba(201, 229, 203, 0.9);
  border-radius: 10px;
  text-shadow: 0px 0px 3px rgb(56, 54, 54), 0px 0px 4px rgb(226, 221, 221);
  color: black;
  font-size: min(20px);
  font: 30px;
  padding: 10px;
}

.waiting-btn-on {
  background-color: rgba(201, 229, 203, 0.9);
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%);
}
.waiting-btn-off {
  display: none;
}

@media (orientation: portrait) {
  .waiting-text-container {
    width: 90%;
    top: 20px;
  }
}
@media (orientation: landscape) {
  .waiting-text-container {
    width: 85%;
    top: 10px;
  }
}
