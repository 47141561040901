.camera-sheet-on {
  font-size: min(7vw, 25px);
  font-weight: bold;
  text-align: center;
  /* margin: -10px; */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  color: white;
}
.camera-sheet-off {
  display: none;
}
.camera-heading {
  font-family: "Brush Script MT";
  font-weight: 200;
  text-shadow: 2px 2px 4px rgb(78, 74, 74), 3px 3px 4px rgb(226, 221, 221);
  margin: 0;
}
.camera-welcome {
  font-size: min(5.5vw, 20px);
  font-family: "Brush Script MT";
  font-weight: 200;
  text-shadow: 2px 2px 4px rgb(78, 74, 74), 3px 3px 4px rgb(226, 221, 221);
}

.camera-module {
  max-width: 270px;
  max-height: 270px;
  width: 95%;
  height: 95%;
  border-radius: 50%;
  background-color: rgb(72, 66, 66);
}
.camera-webcam {
  border-radius: 50%;
  position: relative;
  width: 100%;
  height: 100%;
}

.camera-button-camera,
.camera-button-capture,
.camera-button-reset,
.camera-button-file {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-size: 70%;
  background-color: rgb(135, 132, 148);
  box-shadow: 0px 0px 6px rgb(78, 74, 74), 0px 0px 7px rgb(226, 221, 221);
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-position: center;
}

.camera-button-camera {
  background-image: url("../img/camera.png");
  position: relative;
}
.camera-button-capture {
  background-image: url("../img/capture.png");
  position: relative;
}
.camera-button-reset {
  background-image: url("../img/reset.png");
  position: relative;
}
.camera-button-file {
  background-image: url("../img/file.png");
  position: relative;
}
.camera-button-submit {
  background-image: url("../img/submit.png");
  width: 120px;
  height: 40px;
  border-radius: 30px;
  border: none;
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: 15px;
  text-align: right;
  font-size: 23px;
  box-shadow: 0px 0px 6px rgb(78, 74, 74), 0px 0px 7px rgb(226, 221, 221);
  background-color: rgb(135, 132, 148);
}
.camera-button-submit:disabled {
  box-shadow: none;
  filter: brightness(0.5);
}
/* .button-submit-on {
} */

@media (orientation: portrait) {
  .camera-button-container {
    position: absolute;
    width: 240px;
    top: 15%;
    left: 50%;
    transform: translate(-52.5%, 265px);
    padding-bottom: 10px;
  }

  .camera-module {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .camera-button-camera {
    bottom: 22px;
    left: 2px;
  }
  .camera-button-file {
    bottom: 22px;
    right: 2px;
  }
  .camera-button-submit {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-59%, 340px);
    padding-bottom: 10px;
  }
}
@media (orientation: landscape) {
  .camera-text-cont {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -60%);
    width: 200px;
  }

  .camera-button-container {
    position: absolute;
    align-items: center;
    width: 90px;
    top: 50%;
    right: 35%;
    transform: translate(195px, -48%);
  }
  .camera-button-camera {
    bottom: 0;
    right: 24px;
  }
  .camera-button-file {
    bottom: 0;
    right: 24px;
  }
  .camera-module {
    position: absolute;
    top: 50%;
    right: 35%;
    transform: translate(50%, -50%);
  }
  .camera-button-submit {
    position: absolute;
    left: 20%;
    bottom: 15%;
    transform: translate(-58%, 50%);
  }
}
